import { AiOutlineHome, AiOutlineBook } from "react-icons/ai";
import { BsTable, BsPen, BsPersonSquare } from "react-icons/bs";
import { HiOutlineUserAdd } from "react-icons/hi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { VscCalendar } from "react-icons/vsc";
import { BiBrushAlt, BiBookAlt, BiNotepad } from "react-icons/bi";
import { CgNotes } from "react-icons/cg";
import { GoFold } from "react-icons/go";
import { GiOpenBook } from "react-icons/gi";
import { SiBookstack } from "react-icons/si";
import Library from "../../assets/icons/library icon.svg";
import { DoubleRightOutlined, AppstoreAddOutlined } from "@ant-design/icons";
import { UploadOutlined } from '@ant-design/icons';

// Sidebar Data
export const routes = [
  {
    img: <UploadOutlined />,
    link: "Upload Document",
    path: "/upload_document",
    key: "uploadDocument",
  },
  {
    img: <BiNotepad/>,
    link: "Daily Update",
    path: "daily-update",
    key: "dailyUpdates"
  },
  {
    img: <AiOutlineHome />,
    link: "Home",
    path: "/dashboard",
    key: "dashboard",
  },
  {
    img: <BsTable />,
    link: "Time-Table",
    path: "/time-table",
    key: "time-table",
  },
  {
    img: <HiOutlineUserAdd />,
    link: "Attendance",
    path: "/attendance",
    key: "attendance",
  },
  {
    img: <SiBookstack />,
    link: "Syllabus",
    path: "/syllabus",
    key: "syllabus",
  },

  {
    img: <BsPersonSquare />,
    link: "Subject & Teacher",
    path: "/subject",
    key: "subject",
  },
  {
    img: <CgNotes />,
    link: "Study Material",
    path: "/study",
    key: "study",
  },
  {
    img: <GiOpenBook />,
    link: "Exams",
    path: "/exams",
    key: "exams",
  },
  {
    img: <AiOutlineBook />,
    link: "Results",
    path: "/results",
    key: "results",
  },
  {
    img: <BsPen />,
    link: "Assignments",
    path: "/Assignments",
    key: "Assignments",
  },
  {
    img: <VscCalendar />,
    link: "Holidays and Leaves",
    path: "/holidays",
    key: "holidays",
  },
  {
    img: <FaRegMoneyBillAlt />,
    link: "Fee Details",
    path: "/fee-detail",
    key: "fee-detail",
  },
  {
    img: <GoFold />,
    link: "Cultural",
    path: "/cultural",
    key: "cultural",
  },
  {
    img: <img src={Library} alt="library" />,
    link: "Library",
    path: "/library",
    key: "library",
  },
  {
    img: <BiBrushAlt />,
    link: "Birthday",
    path: "/birthday",
    key: "birthday",
  },
  {
    img: <DoubleRightOutlined />,
    link: "Transport",
    path: "/transport",
    key: "transport",
  },
  {
    img: <AppstoreAddOutlined />,
    link: "Edpedia Store",
    path: "/edpediaStore",
    key: "edpediaStore",
  },
];

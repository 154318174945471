import React from 'react'

import { useEffect, useState } from "react";
import { Button, Empty, notification, Upload } from "antd";
import { uploadDocuments } from '../../Services/UploadDocumentService';

import { UploadOutlined, FileImageOutlined, FilePdfOutlined } from '@ant-design/icons';

import {
  CheckCircleFilled,
  DislikeOutlined,
  InfoCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";

import "./UploadDocument.css"
import { validateFilesWithExtensionsAndSize } from '../../Constants/utilities';
const UploadDocument = ({studentId}) => {
    console.log('studentId111', studentId)
  const [dobDocument, setDobDocument] = useState(null);
  const [aadharCard, setAadharCard] = useState(null);
  const [tc, setTc] = useState(null);
  const [bloodProof, setBloodProof] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImages, setModalImages] = useState({});

  useEffect(() => {
    getUploadCerticates()
  },[])

  const getUploadCerticates = () => {
    uploadDocuments.getUploadedCerticates(studentId)
        .then(response => {
            console.log('response', response)
            setDobDocument(response?.data?.dobCertificate)
            setAadharCard(response?.data?.aadhaarCardCopies)
            setBloodProof(response?.data?.bloodGroupProof)
            setTc(response?.data?.tcBonaFide)
        }).catch(error => {
            console.log('error', error)
        })
  }

  const accept = ".png, .jpg, .jpeg, .doc, .docx, .pdf";
    const extensions = [".doc", ".docx", "png", "jpeg", "jpg", "pdf"];
    const maxSizeMB = 10;
    const errorMessage = "File should be png/jpeg/jpg/doc/docx/pdf format and below 10 MB only.";


    const handleDobDocumentUpload = ({ fileList }) => {
      const validFiles = validateFilesWithExtensionsAndSize(fileList, extensions, maxSizeMB, errorMessage);
      if(validFiles.length > 0) {
        console.log('validFiles', validFiles)
        const formData = new FormData()
        formData.append("birthCertificate", validFiles[0])
        uploadDocuments.uploadDobCertificate(studentId, formData)
            .then(response => {
                console.log('response', response)
                getUploadCerticates()
            }).catch(error => {
                console.log('error', error)
            })
      }
    };

    const handleAadhaarUpload = ({ fileList }) => {
      const validFiles = validateFilesWithExtensionsAndSize(fileList, extensions, maxSizeMB, errorMessage);
    if(validFiles.length > 0) {
      console.log('validFiles', validFiles)
      const formData = new FormData()
      formData.append("aadhaarCopy", validFiles[0])
      uploadDocuments.uploadAaadharCopy(studentId, formData)
          .then(response => {
              console.log('response', response)
              getUploadCerticates()
          }).catch(error => {
              console.log('error', error)
          })
    }
  };

  const handleBloodProofUpload = ({ fileList }) => {
    const validFiles = validateFilesWithExtensionsAndSize(fileList, extensions, maxSizeMB, errorMessage);
    if(validFiles.length > 0) {
      console.log('validFiles', validFiles)
      const formData = new FormData()
      formData.append("bloodGroupProof", validFiles[0])
      uploadDocuments.uploadBloodProofCopy(studentId, formData)
          .then(response => {
              console.log('response', response)
              getUploadCerticates()
          }).catch(error => {
              console.log('error', error)
          })
    }
};

const handleTCUpload = ({ fileList }) => {
  const validFiles = validateFilesWithExtensionsAndSize(fileList, extensions, maxSizeMB, errorMessage);
    if(validFiles.length > 0) {
      console.log('validFiles', validFiles)
      const formData = new FormData()
      formData.append("tc", validFiles[0])
      uploadDocuments.uploadTcCertificate(studentId, formData)
          .then(response => {
              console.log('response', response)
              getUploadCerticates()
          }).catch(error => {
              console.log('error', error)
          })
    }
};

const renderFile = (file, label) => {
  const isImage = file && (file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg'));
  const isPDF = file && file.endsWith('.pdf');
  return (
      <div style={{ textAlign: "center", height: "400px", width: "270px", marginTop: "20px", marginBottom: "10px" }}>
          <h1>{label}</h1>
          {isImage ? (
              <FileImageOutlined
                  style={{ fontSize: '40px', cursor: 'pointer', marginTop: '15px' }}
                  onClick={() => window.open(file, '_blank')}
              />
          ) : (
              isPDF && (
                  <FilePdfOutlined
                      style={{ fontSize: '40px', cursor: 'pointer', marginTop: '15px' }}
                      onClick={() => window.open(file, '_blank')}
                  />
              )
          )}
      </div>
  );
};
    return (
      <main style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Student Documents</h1>
        </div>
        <div className="main-document-request-contain">
          <section className="document-request-container">
            <div className="total_fees">
              <div className="back_container bg1">
                <p>1. Date of Birth Certificate</p>
                <Upload
                  className="blue-button"
                  onChange={handleDobDocumentUpload}
                  type="file"
                  accept={accept}
                  showUploadList={false}
                  beforeUpload={() => false}
                  maxCount={1}
                >
                  <UploadOutlined style={{fontSize:"30px", margin:"7px"}}/>
                </Upload>
              </div>
              <div className="back_container bg4">
                <p>2. Transfer Certificate (TC)</p>
                <Upload
                  className="blue-button"
                  onChange={handleTCUpload}
                  type="file"
                  accept={accept}
                  showUploadList={false}
                  beforeUpload={() => false}
                  maxCount={1}
                >
                  <UploadOutlined style={{fontSize:"30px", margin:"7px"}}/>
                </Upload>
              </div>
              <div className="back_container bg2">
                <p>3. Aadhaar Card </p>
                <Upload
                  className="blue-button"
                  onChange={handleAadhaarUpload}
                  type="file"
                  accept={accept}
                  showUploadList={false}
                  beforeUpload={() => false}
                  maxCount={1}
                >
                  <UploadOutlined style={{fontSize:"30px", margin:"7px"}}/>
                </Upload>
              </div>
              <div className="back_container bg5">
                <p>4. Blood Proof </p>
                <Upload
                  className="blue-button"
                  onChange={handleBloodProofUpload}
                  type="file"
                  accept={accept}
                  showUploadList={false}
                  beforeUpload={() => false}
                  maxCount={1}
                >
                  <UploadOutlined style={{fontSize:"30px", margin:"7px"}}/>
                </Upload>
              </div>

              {/* <div className="back_container bg3">
                <p>5. Report Card </p>
                <Upload
                  className="blue-button"
                  onChange={handleDobDocumentUpload}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  showUploadList={false}
                  beforeUpload={() => false}
                >
                  <UploadOutlined style={{fontSize:"30px", margin:"7px"}}/>
                </Upload>
              </div> */}
            </div>
          </section>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                {dobDocument && renderFile(dobDocument, "DOB Certificate")}
                {tc && renderFile(tc, "Transfer Certificate")}
                {aadharCard && renderFile(aadharCard, "Aadhar Card")}
                {bloodProof && renderFile(bloodProof, "Blood Proof")}
            </div>
      </main>
    );
}

export default UploadDocument
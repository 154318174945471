import {
  GET_TODAY_TIMETABLE,
  GET_TODAY_TIMETABLE_END_PONT,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_ENDPOINT,
  FINDSTUDENT,
  FIND_STUDENT_ENDPOINT,
  EDITSTUDENT,
  EDIT_STUDENT_ENDPOINT,
  DOWNLOAD_SYLLABUS,
  SIDEBAR_COMPONENTS,
  PARENT_FIND,
  PARENT_FIND_ENDPOINT,
  TODAY_DAILY_UPDATE,
  GET_DAILY_UPDATE,
} from "../Constants/constants";
import { GetAPIRequest, PostAPIRequest } from "./Api";

export const getTodayTimeTableList = (id) => {
  return GetAPIRequest({
    url: GET_TODAY_TIMETABLE + id + GET_TODAY_TIMETABLE_END_PONT,
  });
};
export const UploadStudentImage = (studentId, payload) => {
  return PostAPIRequest({
    url: UPLOAD_IMAGE + studentId + UPLOAD_IMAGE_ENDPOINT,
    data: payload,
  });
};
const findStudentByStudentId = (studentId) => {
  return GetAPIRequest({
    url: FINDSTUDENT + studentId + FIND_STUDENT_ENDPOINT,
  });
};

const findStudentListByStudentId = (studentId) => {
  return GetAPIRequest({
    url: FINDSTUDENT + studentId + FIND_STUDENT_ENDPOINT,
  });
};
const editStudent = (studentId, payload) => {
  return PostAPIRequest({
    url: EDITSTUDENT + studentId + EDIT_STUDENT_ENDPOINT,
    data: payload,
  });
};

const sidebarComponents = (params) => {
  return GetAPIRequest({ url: SIDEBAR_COMPONENTS, params });
};

const parentFind = (adhar) => {
  return GetAPIRequest({ url: PARENT_FIND + adhar + PARENT_FIND_ENDPOINT });
};

const getDailyUpdate = (classId, params) => {
  return GetAPIRequest({ url: TODAY_DAILY_UPDATE + classId + GET_DAILY_UPDATE, params})
}
export const todayTimeTableService = {
  getTodayTimeTableList,
  UploadStudentImage,
  findStudentByStudentId,
  findStudentListByStudentId,
  editStudent,
  parentFind,
  sidebarComponents,
  getDailyUpdate
};

import { Card, DatePicker } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { YearMonthDate } from '../../Constants/utilities'

import "./DailyUpdate.scss"
import { todayTimeTableService } from '../../Services/TodayTimeTableService'

const DailyUpdate = ({classId, studentId}) => {
    console.log('classId', classId)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [points, setPoints] = useState([])

    const handleDateChange = (date) => {
        const selectedDate = YearMonthDate(date)
        setSelectedDate(selectedDate)
    }

    useEffect(() => {
        getTodayDailyUpdateList()
    }, [])

    const getTodayDailyUpdateList = () => {
        const dateOnly = new Date(selectedDate).toISOString().split('T')[0];
        
        const params = { date: dateOnly }; 
    
        todayTimeTableService.getDailyUpdate(classId, params)
            .then(response => {
                if (response?.data && response?.data.length > 0) {
                    const notes = response?.data[0]?.notes || [];
                    const updatedNotes = notes.map(note => `→ ${note.trim()}`);
                    setPoints(updatedNotes); 
                } else {
                    setPoints([]);
                }
            })
            .catch(error => {
                console.error('Error fetching daily updates:', error);
            });
    };
    

  return (
    <main>
        <div className='daily-updates-main-container'>
            <h1>Daily Updates</h1>
            <DatePicker
                    value={
                        selectedDate
                          ? moment(selectedDate, "YYYY-MM-DD")
                          : null
                      }
                    className='dailyUpdate-datePicker'
                    onChange={(date) => handleDateChange(date)}
                />
        </div>
        <Card className='preview-container'>
            <h1>Dear Parents</h1>
            <p>We are delighted to share the daily updates for Student Name at School Name for</p>
            <p>Activities</p>
            {points?.map(point => (
                <p key="point">{point}</p>
            ))}
            <p>Thank you for your continued support in making School Name a nurturing place for your child's growth and Learning</p>
            <p>Regards,</p>
            <p>School Name,</p>
            <p>Address</p>
        </Card>
    </main>
  )
}

export default DailyUpdate
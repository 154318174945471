import React from "react";
import "./Notification.css";
import EmptyNotification from "../EmptyComponent/EmptyNotification";
import { useUpdateReadStatusMutation } from "../../RTKQuery/Notification";
const NotificationModal = ({ notificationData, refetchNotificationData }) => {
  const [updateNotifiationReadStatus, { error: updateReadStatusError }] =
    useUpdateReadStatusMutation();

  const handleNotificationClick = (notificationId) => {
    updateNotifiationReadStatus({
      id: notificationId,
      readStatus: true,
    })
      .unwrap()
      .then(() => {
        refetchNotificationData();
      });
  };

  return (
    <div className="card-list">
      {notificationData.map((card, index) => (
        <div
          key={index}
          className={`card ${card?.readState ? "read" : "unread"}`}
          onClick={() => handleNotificationClick(card?.id)}
        >
          <h1 className="date">{card.sentDate}</h1>
          <span className="content">{card.notificationMsg}</span>
        </div>
      ))}
    </div>
  );
};

export default NotificationModal;

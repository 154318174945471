import {
    DOCUMENT_UPLOAD,
    DOCUMENT_UPLOAD_ENDPOINT,
    UPLOAD_CERTIFICATE,
    UPLOAD_DOB_CERTIFICATE_ENDPOINT,
    GET_UPLOADED_CERFICATE,
    GET_UPLOADED_CERFICATE_ENDPOINT,
    UPLOAD_AADHAAR_CERTIFICATE_ENDPOINT,
    UPLOAD_BLOOD_PROOF_ENDPOINT,
    UPLOAD_TC_ENDPOINT
} from "../Constants/constants"

import { GetAPIRequest, PostAPIRequest } from "./Api";

export const postStudentDocuments = (id) => {
    return PostAPIRequest({
        url: DOCUMENT_UPLOAD + id + DOCUMENT_UPLOAD_ENDPOINT
    })
}

export const uploadDobCertificate = (id, payload) => {
    return PostAPIRequest({
        url: UPLOAD_CERTIFICATE + id + UPLOAD_DOB_CERTIFICATE_ENDPOINT, data: payload
    })
}

export const uploadAaadharCopy = (id, payload) => {
    return PostAPIRequest({
        url: UPLOAD_CERTIFICATE + id + UPLOAD_AADHAAR_CERTIFICATE_ENDPOINT, data: payload
    })
}

export const uploadBloodProofCopy = (id, payload) => {
    return PostAPIRequest({
        url: UPLOAD_CERTIFICATE + id + UPLOAD_BLOOD_PROOF_ENDPOINT, data: payload
    })
}

export const uploadTcCertificate = (id, payload) => {
    return PostAPIRequest({
        url: UPLOAD_CERTIFICATE + id + UPLOAD_TC_ENDPOINT, data: payload
    })
}

export const getUploadedCerticates = (id) => {
    return GetAPIRequest({
        url: GET_UPLOADED_CERFICATE + id + GET_UPLOADED_CERFICATE_ENDPOINT
    })
}

export const uploadDocuments = {
    postStudentDocuments,
    getUploadedCerticates,
    uploadDobCertificate,
    uploadTcCertificate,
    uploadBloodProofCopy,
    uploadAaadharCopy
}
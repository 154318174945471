import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Tag,
  Select,
  Row,
  Col,
  Table,
  notification,
  Upload,
  Avatar,
  Spin,
  DatePicker,
} from "antd";
import { LikeTwoTone, CheckCircleFilled } from "@ant-design/icons";
import { todayTimeTableService } from "../../../Services/TodayTimeTableService";
import "./ProfileDetails.scss";
import InputFloatLabel from "../../Common/FloatLable/Input";
import SelectFloatLabel from "../../Common/FloatLable/Select";
import TextAreaFloatLabel from "../../Common/FloatLable/TextArea";
import Cookies from "js-cookie";
import Schoolname from "../../School/Schoolname";
import moment from "moment";
import girl from "../../../assets/images/girl.png";
import boy from "../../../assets/images/Boy.png";
import { useSelector } from "react-redux";
import Datepicker from "../../Common/FloatLable/Datepicker";
import dayjs from "dayjs";
const ProfileDetails = () => {
  const [staffData, setStaffData] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [teachingSubjects, setTeachingSubjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [form] = Form.useForm();
  const [instLogoForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [staffAllDetails, setSatffAllDetails] = useState([]);

  // const [studentId, setStudentId] = useState(Cookies.get("userId"))

  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);

  const studentId = studentInfo?.studentId;
  console.log("staff data", staffData);

  // console.log("Selected Student ID:", selectedStudentId);
  // useEffect(() => {
  //   console.log(studentId);
  // }, [studentId]);

  useEffect(() => {
    studentDetails();
  }, [studentId]);

  useEffect(() => {
    const momentDateOfBirth =
      staffData?.dateOfBirth === undefined
        ? dayjs(new Date(), "YYYY-MM-DD")
        : dayjs(staffData?.dateOfBirth, "YYYY-MM-DD");
    const momentDateOfJoining =
      staffData?.joiningDate === undefined
        ? dayjs(new Date(), "YYYY-MM-DD")
        : dayjs(staffData?.joiningDate, "YYYY-MM-DD");
    form.setFieldsValue({
      studentName: staffData.studentName,
      contact: staffData.contact,
      email: staffData.email,
      address: staffData.address,
      city: staffData.city,
      state: staffData.state,
      country: staffData.country,
      dateOfBirth: momentDateOfBirth,
      nationality: staffData.nationality,
      gender: staffData.gender,
      className: staffData.className,
      section: staffData.section,
      studentAdharNumber: staffData.studentAdharNumber,
      joiningDate: momentDateOfJoining,
      primaryTeacher: staffData.primaryTeacher,
      secondaryTeacher: staffData.primaryTeacher,
      studentAadhaarNumber: staffData.studentAadhaarNumber,
      parentWhatsappNumber: staffData.studentAadhaarNumber,
      rollNo: staffData.rollNo,
      parentEmail: staffData.parentEmail,
      motherName: staffData.motherName,
      parentAadhaarNumber: staffData.parentAadhaarNumber,
      postalCode: staffData.postalCode,
      fatherName: staffData.fatherName,
      academicYear: staffData.academicYear,
      attendedYear: staffData.attendedYear,
      parentContactNumber: staffData.parentContactNumber,
      // "data": {
      //   "studentId": 3,
    });
  }, [staffData, form]);

  const studentDetails = () => {
    todayTimeTableService
      .findStudentByStudentId(studentId)
      .then((response) => {
        let findSubjects = response.data.classSubjectsInfo;
        let StaffInfo = response?.data;
        setTeachingSubjects(findSubjects);
        if (response.status === 200) {
          console.log('response?.data', response?.data)
          setUploading(false);
          setStaffData(StaffInfo);
          setSatffAllDetails(response.data);
          setSubjects(response.data.subjectsInfo);
          setClasses(response.data.classesInfo);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleFormChange = () => {
    setFormChanged(form.isFieldsTouched());
  };

  const onFinish = (values) => {
    setIsEditMode(false);

    const {
      studentName,
      contact,
      email,
      address,
      city,
      state,
      country,
      nationality,
      gender,
      className,
      section,
      studentAdharNumber,
      primaryTeacher,
      secondaryTeacher,
      studentAadhaarNumber,
      parentWhatsappNumber,
      rollNo,
      parentEmail,
      motherName,
      parentAadhaarNumber,
      postalCode,
      fatherName,
      academicYear,
      attendedYear,
      parentContactNumber,
      classId,
    } = values;
    const splitOnSpace = studentName.split(" ");
    const firstName = splitOnSpace[0];
    const lastName = splitOnSpace[1];
    const momentDateOfBirth2 = dayjs(values.dateOfBirth).format("YYYY-MM-DD");
    const momentDateOfJoining2 = dayjs(values.joiningDate).format("YYYY-MM-DD");
    const payload = {
      firstName: firstName,
      lastName: lastName,
      classId: staffData.classId,
      contact,
      email,
      address,
      city,
      state,
      country,
      dateOfBirth: momentDateOfBirth2,
      nationality,
      gender,
      className,
      section,
      studentAdharNumber,
      joiningDate: momentDateOfJoining2,
      primaryTeacher: staffData.primaryTeacher,
      secondaryTeacher: staffData.secondaryTeacher,
      studentAadhaarNumber,
      parentWhatsappNumber: staffData.parentWhatsappNumber,
      rollNo: staffData.rollNo,
      parentEmail: staffData.parentEmail,
      motherName,
      parentAadhaarNumber,
      postalCode,
      fatherName,
      academicYear: staffData.academicYear,
      attendedYear: staffData.attendedYear,
      parentContactNumber,
    };
    todayTimeTableService
      .editStudent(studentId, payload)
      .then((response) => {
        if (response.status === 200) {
          setStaffData(response.data);

          notification.success({
            description: `Student Information Updated Successfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          setFormChanged(false);
          studentDetails();
        }
      })
      .catch((err) => console.log(err));
  };

  const onFinishFailed = (errorInfo) => {};

  // profile

  const handleUploadChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFileList([file]);
      setLogoImage(URL.createObjectURL(file));
      handleProfileUpload({ fileList: [file] });
    }
  };

  const handleProfileUpload = (values) => {
    const { fileList } = values;
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append(`profilePic`, file);
    });
    const payload = formData;

    if (fileList.length > 0) {
      setUploading(true);
      todayTimeTableService
        .UploadStudentImage(studentId, payload)
        .then((res) => {
          studentDetails();
          instLogoForm.resetFields();
          setUploading(false);
          if (res.status === 200) {
            notification.success({
              description: `Profile picture has been updated successfully`,
              icon: (
                <CheckCircleFilled
                  style={{
                    color: "green",
                  }}
                />
              ),
              duration: 3,
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          setUploading(false);
          if (err) {
            notification.error({
              description:
                "An error occurred while Uploading Profile , try again later.",
              duration: 5,
              placement: "topRight",
            });
          }
        });
    } else {
      setUploading(false);
    }
  };

  const handleImageClick = () => {
    const uploadInput = document.getElementById("uploadInput");
    if (uploadInput) {
      uploadInput.click();
    }
  };

  return (
    <>
      <Schoolname />
      <section className="myProfile">
        <div className="profile-card card">
          <div className="ProfileName">
            <p className="profiletext">Profile</p>

            <Form form={instLogoForm} onFinish={handleProfileUpload}>
              <Form.Item name="instLogo">
                <div onClick={handleImageClick} style={{ cursor: "pointer" }}>
                  <Spin spinning={uploading} size="large">
                    {staffAllDetails?.imageUrl ? (
                      <img
                        src={staffAllDetails?.imageUrl || logoImage}
                        alt={staffAllDetails?.name}
                        className="studentprofile-img"
                        loading="lazy"
                        style={{
                          width: 150,
                          height: 150,
                          borderRadius: "50%",

                          marginBlock: 5,
                        }}
                        onError={(e) => {
                          e.target.src = girl;
                        }}
                      />
                    ) : (
                      <Avatar
                        style={{
                          width: 150,
                          height: 150,
                          borderRadius: "50%",

                          marginBlock: 5,
                        }}
                        src={staffAllDetails?.gender === "Male" ? boy : girl}
                        alt="user"
                      />
                    )}
                  </Spin>
                  <span className="name">Name: {staffAllDetails?.studentName}</span>
                  <span className="name">Roll No: {staffAllDetails?.rollNo}</span>
                  <span className="name">Admission No: {staffAllDetails?.admissionId}</span>
                </div>
              </Form.Item>

              <input
                id="uploadInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleUploadChange}
              />
            </Form>
          </div>
          <div>
            <Form
              className="profile-form"
              form={form}
              name="edit-profile"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              onValuesChange={handleFormChange}
            >
              <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 32 }}>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="studentName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Your Name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="contact"
                    rules={[
                      {
                        required: true,
                        message: "Please input your contact!",
                      },
                      {
                        min: 10,
                        message: "please enter valid phone number",
                      },
                      {
                        max: 10,
                        message: "cannot exceed more than 10",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Mobile Number" type="number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="dateOfBirth"
                    rules={[{ required: true, message: "" }]}
                  >
                    <Datepicker label="Date of Birth" format="DD-MM-YYYY" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="gender"
                    className="form-item"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <SelectFloatLabel label="Gender">
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </SelectFloatLabel>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="email"
                    rules={[{ required: true, message: "" }]}
                  >
                    <InputFloatLabel label="Email" type="email" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="academicYear"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Academic Year" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="attendedYear"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Attended Year" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="className"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Class Name" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="section"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Section" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="fatherName"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Father Name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="motherName"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Mother Name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="nationality"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Nationality" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Country" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="City" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="State" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="joiningDate"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <DatePicker
                      label="joining Date"
                      format="DD-MM-YYYY"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="studentAadhaarNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Adhar Number!",
                      },
                      {
                        min: 12,
                        message: "please enter valid Aadhar number",
                      },
                      {
                        max: 12,
                        message: "cannot exceed more than 12 numbers",
                      },
                    ]}
                  >
                    <InputFloatLabel
                      label="Student Aadhaar Number"
                      type="number"
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="parentAadhaarNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Aadhar Number!",
                      },
                      {
                        min: 12,
                        message: "please enter valid Aadhar number",
                      },
                      {
                        max: 12,
                        message: "cannot exceed more than 12",
                      },
                    ]}
                  >
                    <InputFloatLabel
                      label="Parent Aadhaar Number"
                      type="number"
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="parentContactNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Contact!",
                      },
                      {
                        min: 10,
                        message: "please enter valid Contact",
                      },
                      {
                        max: 10,
                        message: "cannot exceed more than 10",
                      },
                    ]}
                  >
                    <InputFloatLabel
                      label="Parent Contact Number"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="primaryTeacher"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Primary Teacher" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="secondaryTeacher"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputFloatLabel label="Secondary Teacher" disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="postalCode"
                    rules={[
                      {
                        required: true,
                        min: 6,

                        message: "There must only be 6 characters",
                        max: 6,
                      },
                    ]}
                  >
                    <InputFloatLabel label="PostalCode" type="number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="form-item"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <TextAreaFloatLabel label="Address" />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="blue-button"
                  style={{ float: "right", marginTop: 10 }}
                >
                  Save
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileDetails;
